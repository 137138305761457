import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './SalesPackage.css'; // Custom CSS file for styling

const packages = [
  {
    title: "Day Pass",
    price: "$15.00",
    link:'https://southernstrengthfl.gymmasteronline.com/portal/membership/4e8a0d174ff8872025b52ec1e248d5c0',
    features: [""],
  },
  {
    title: "Monthly",
    price: "$45.00",
    link:'https://southernstrengthfl.gymmasteronline.com/portal/membership/df09198458fe9b32b3cc15efdfc70a2d',
    features: [""],
  },
  {
    title: "Strength & Recovery Membership",
    price: "$75.00",
    link:'https://southernstrengthfl.gymmasteronline.com/portal/membership/5874987431e6ad4b6081be8c42f8f86d',
    features: [
      "Includes unlimited use of recovery room and gym basic services",
      "$45.00 Signup Fee"
    ],
  }
];

const handleClick = (link) => {
  // Replace with the URL you want to navigate to
  window.location.href = link;
};

const SalesPackage = () => (
  <section className="sales-package-section set-relative" id="pricing">
    <Container>
      <Row>
        <Col md="12">
          <div className="title text-center">
            <h6 className="borders-before"><span>OUR PACKAGES</span></h6>
            <h2 className="main-title">Choose Your Plan</h2>
            <p className="sub-title">Get instant access to our gym 24/7 as soon as you sign up through our app with Bluetooth functionality.</p>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {packages.map((pkg, index) => (
          <Col md="4" key={index} className="mb-4 d-flex justify-content-center">
            <div className="pricing-box">
              <h3 className="package-title">{pkg.title}</h3>
              <div className="package-price">{pkg.price}</div>
              <ul className="package-features">
                {pkg.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
              {/* Add the button here */}
              <button className="btn-primary" onClick={() => handleClick(pkg.link)}>Select Plan</button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);

export default SalesPackage;
